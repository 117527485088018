@import "../config/variables";
@import "../config/mixins";

$logo-msc-width: 75px;

.splash {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: $primary-blue;
  color: $white;

  &__content {
    width: calc(100% - 2 * #{$large-gap});
    font-size: 3rem;
    text-align: center;
  }

  &__logo {
    max-width: 668px;
    margin: 0 auto $general-gap;

    h1 {
      font-size: 0;
    }

    img {
      width: 100%;
      vertical-align: bottom;
    }
  }

  &__location {
    font-weight: 500;
    font-size: 1.5rem;
    text-align: center;
    color: $light-blue-gray;
    letter-spacing: 0.075rem;
  }

  &__tokyo-link {
    position: absolute;
    top: 0;
    right: 0;
    padding: $general-gap $large-gap;
    color: $white;
    font-size: $large-font;
    font-weight: 500;

    @include link-with-arrow;

    &::before {
      display: inline-block;
      width: 16px;
      height: 26px;
      margin-right: $small-gap;
      background: url("../../images/icon-tokyo-tower.svg") center / contain no-repeat;
      content: "";
    }
  }

  &__scroll-link {
    position: absolute;
    bottom: 0;
    width: 200px;
    padding: $large-gap 0;
    color: $white;
    text-align: center;

    &::after {
      display: block;
      width: 20px;
      height: 18px;
      margin: $small-gap auto 0;
      background: url("../../images/arrow-short-down.svg") center / contain no-repeat;
      content: "";
    }

    &:hover {
      @keyframes scroll-link-arrow {
        0% {
          transform: translateY(-$small-gap);
          opacity: 0;
        }

        100% {
          transform: translateY(0);
          opacity: 1;
        }
      }

      &::after {
        animation: scroll-link-arrow $transition-time ease-in-out;
      }
    }
  }

  &__hiring-link {
    position: absolute;
    bottom: $large-gap;
    right: $large-gap;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 96px;
    height: 96px;
    color: $white;
    font-size: 0.8125rem;
    font-weight: 500;
    background-color: $light-blue;
    border-radius: 50%;
    transition: transform $transition-time ease-in-out;

    &::after {
      position: absolute;
      top: calc(50% + 13px);
      left: calc(50% - 9px);
      display: block;
      width: 18px;
      height: 18px;
      background: url("../../images/arrow-short-down.svg") center / contain no-repeat;
      content: "";
      transform: rotate(225deg);
      transform-origin: center;
    }

    &:hover {
      transform: scale(1.1);
      transform-origin: center;
    }
  }
}

.section {
  & &__marker {
    position: absolute;
    top: -$header-height;
  }

  &#{&}--about-us {
    .content-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include media-breakpoint-down($medium-screen) {
        display: block;
      }

      .image {
        max-width: 615px;
        width: 50%;
        flex-shrink: 0;

        @include media-breakpoint-down($medium-screen) {
          width: 100%;
          max-width: none;
          text-align: center;
        }

        img {
          width: 100%;
          vertical-align: bottom;
          border-radius: $border-radius;
        }
      }

      .text {
        margin-left: 3.5rem;

        @include media-breakpoint-down($medium-screen) {
          @include medium-screen-extra-side-margin;
        }
      }
    }
  }

  &#{&}--motto {
    padding: 0;
    background-color: $blue;
    color: $white;

    .content-wrapper {
      position: relative;
      padding: 4rem 0;

      @include media-breakpoint-down($medium-screen) {
        padding: 3rem 0 calc(67% + #{$general-gap * 2});
      }

      .image {
        position: absolute;
        right: 0;
        top: 0;
        height: 100%;
        width: 55.86%;
        overflow: hidden;
        mix-blend-mode: overlay;

        @include media-breakpoint-down($medium-screen) {
          width: 100%;
          height: 0;
          top: auto;
          bottom: 0;
          padding-top: 67%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
          vertical-align: bottom;

          @include media-breakpoint-down($medium-screen) {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
        }
      }

      .text {
        display: flex;
        justify-content: end;
        width: calc(50% + 75px);
        margin-bottom: 2rem;
        padding: 4rem 4rem 4rem 0;
        background-color: rgba($dark-blue, 0.7);
        background-blend-mode: darken;

        @include media-breakpoint-down($medium-screen) {
          width: calc(100% - #{$large-gap});
          padding: 1.5rem $large-gap 1.5rem 0;
          box-sizing: border-box;
        }

        .text-content-wrapper {
          max-width: calc(#{$wrapper-width} / 2 + 75px);
          margin-left: $large-gap;

          h2 {
            color: $light-blue;

            &.large {
              color: $white;
              font-size: 2rem;
            }
          }

          p {
            @include media-breakpoint-down($medium-screen) {
              @include medium-screen-extra-side-margin;
            }
          }
        }
      }

      .career-link {
        color: $white;

        @include link-with-arrow;

        @include media-breakpoint-down($medium-screen) {
          @include medium-screen-extra-side-margin;
        }
      }
    }
  }

  &#{&}--service {
    padding: 0;

    ul {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
      list-style: none;

      li {
        display: block;
      }
    }

    .service-content-wrapper {
      padding-top: 4rem;
      text-align: center;
      background: $light-blue-gray-2;

      @include media-breakpoint-down($medium-screen) {
        padding: 3rem 0 $general-gap;
      }

      p {
        @include media-breakpoint-down($medium-screen) {
          @include medium-screen-extra-side-margin;
        }
      }

      ul {
        margin: 3.5rem 0 0;

        .service-list {
          width: 20%;
          padding: 0 $xsmall-gap;
          margin-bottom: 4rem;
          text-align: center;
          font-size: $large-font;
          box-sizing: border-box;

          @include media-breakpoint-down($medium-screen) {
            width: 25%;
            margin-bottom: 2rem;
            font-size: $normal-font;
          }

          @include media-breakpoint-down($small-screen) {
            width: 33.33%;
          }

          @include media-breakpoint-down($xsmall-screen) {
            width: 50%;
          }

          &::before {
            display: block;
            width: 120px;
            height: 120px;
            margin: 0 auto 1rem;
            content: "";
            background: url("../../images/tech-sprites.png") no-repeat center / cover;
          }

          &.service-list--website {
            &::before {
              background-position: 0 -959px;
            }
          }

          &.service-list--mobile-app {
            &::before {
              background-position: 0 -836px;
            }
          }

          &.service-list--cloud-devops {
            &::before {
              background-position: 0 -358px;
            }
          }

          &.service-list--e-commerce {
            &::before {
              background-position: 0 -480px;
            }
          }

          &.service-list--live-stream {
            &::before {
              background-position: 0 -1079px;
            }
          }

          &.service-list--iot {
            &::before {
              background-position: 0 -718px;
            }
          }

          &.service-list--blockchain {
            &::before {
              background-position: 0 0;
            }
          }

          &.service-list--ad-tech {
            &::before {
              background-position: 0 -119px;
            }
          }

          &.service-list--digital-marketing {
            &::before {
              background-position: 0 -599px;
            }
          }

          &.service-list--design {
            &::before {
              background-position: 0 -234px;
            }
          }
        }
      }
    }

    .language-framework-content-wrapper {
      padding: 4rem 0;

      @include media-breakpoint-down($medium-screen) {
        padding: 3rem 0;
      }

      .wrapper {
        max-width: 890px;
        text-align: center;
      }

      ul {
        margin: auto auto 3rem;
        justify-content: space-between;

        @include media-breakpoint-down($medium-screen) {
          margin-bottom: 2rem;
        }

        li {
          width: 14.285%;
          text-align: center;

          @include media-breakpoint-down($medium-screen) {
            width: 100px;
          }

          img {
            width: 100%;
            max-width: 108px;
          }
        }
      }

      p {
        @include media-breakpoint-down($medium-screen) {
          @include medium-screen-extra-side-margin;
        }
      }

      .button {
        margin-top: 4rem;

        @include media-breakpoint-down($medium-screen) {
          margin-top: 3rem;
        }
      }

      .career-link {
        margin-top: 2rem;
        font-size: $large-font;
        font-weight: 500;

        @include media-breakpoint-down($medium-screen) {
          font-size: $normal-font;
        }

        @include link-with-arrow;

        &::after {
          width: 14px;
          height: 12px;
          filter: invert(0.75) sepia(100%) hue-rotate(190deg) saturate(500%);
        }
      }
    }
  }

  &#{&}--location {
    background: $light-blue-gray-2;

    .location {
      &:last-child {
        padding-top: 4rem;
        margin-top: 4rem;
        border-top: 1px solid $light-blue-gray;
      }

      h2 {
        display: flex;
        align-items: center;

        @include media-breakpoint-down($medium-screen) {
          margin-left: 0;
          margin-right: 0;
        }

        &::before {
          display: inline-block;
          width: 20px;
          height: 27px;
          margin-right: $small-gap;
          background: center / contain no-repeat;
          content: "";
        }
      }

      .content-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include media-breakpoint-down($medium-screen) {
          display: block;
        }

        .image {
          max-width: 392px;
          width: 50%;
          flex-shrink: 0;

          @include media-breakpoint-down($medium-screen) {
            width: 100%;
            max-width: none;
            margin-top: $large-gap;
          }

          img {
            width: 100%;
            vertical-align: bottom;
            border-radius: $border-radius;
          }
        }

        .text {
          position: relative;
          width: calc(100% - 2rem);
          margin-right: 2rem;
          font-size: $large-font;

          @include media-breakpoint-down($medium-screen) {
            width: 100%;
            margin-right: 0;
            font-size: $small-font;

            @include medium-screen-extra-side-margin;
          }

          dl {
            display: grid;
            grid-template-columns: max-content auto;
            grid-row-gap: 1.625rem;

            @include media-breakpoint-down($medium-screen) {
              grid-row-gap: $general-gap;
            }
          }

          dt {
            font-weight: 500;
            grid-column-start: 1;
          }

          dd {
            grid-column-start: 2;
          }
        }
      }

      &.location--kl {
        h2 {
          &::before {
            background-image: url("../../images/icon-petronas-twin-tower.svg");
          }
        }

        .content-wrapper {
          .image {
            position: relative;

            @include media-breakpoint-down(900px) {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }
          }

          .office-image {
            @include media-breakpoint-down(900px) {
              width: calc(100% - #{$logo-msc-width} - #{$general-gap});
            }

            @include media-breakpoint-down($medium-screen) {
              width: calc(100% - #{$logo-msc-width} - #{$general-gap * 2});
            }
          }

          .logo-msc {
            position: absolute;
            bottom: 0;
            right: calc(100% + 2rem);
            width: $logo-msc-width;
            height: 71px;
            flex-shrink: 0;

            @include media-breakpoint-down(900px) {
              position: static;
            }
          }
        }
      }

      &.location--tokyo {
        h2 {
          &::before {
            background-image: url("../../images/icon-tokyo-tower.svg");
          }
        }

        .image {
          display: flex;
          justify-content: space-between;

          img {
            width: 47.45%;
          }
        }
      }
    }
  }
}
