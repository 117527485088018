// button / link hover effect
@mixin button-link-transition {
  transition: filter $transition-time;

  &:hover {
    filter: brightness(1.4);
  }
}

@mixin link-with-arrow {
  display: inline-flex;
  align-items: center;

  &::after {
    display: inline-block;
    width: 12px;
    height: 10px;
    margin-left: $xsmall-gap;
    background: url("../../images/arrow-right.svg") center / contain no-repeat;
    content: "";
  }

  &:hover {
    @keyframes tokyo-link-arrow {
      0% {
        transform: translateX(-$xsmall-gap);
        opacity: 0;
      }

      100% {
        transform: translateX(0);
        opacity: 1;
      }
    }

    &::after {
      animation: tokyo-link-arrow $transition-time ease-in-out;
    }
  }
}

@mixin blank-list {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin media-breakpoint-down($size) {
  @media (max-width: $size) {
    @content;
  }
}

@mixin medium-screen-extra-side-margin {
  margin-left: $general-gap;
  margin-right: $general-gap;
}
