// Color
$white: #fff;
$black: #222224;
$primary-blue: #152644;
$blue: #395daa;
$blue-2: #93b5ff;
$dark-blue: #001438;
$light-blue: #4571d0;
$light-gray: #eee;
$light-blue-gray: #ced4da;
$light-blue-gray-2: #f8f9fa;

// Size
$xsmall-gap: 0.4375rem; // 7px
$small-gap: 0.625rem; // 10px
$general-gap: 1rem; // 16px
$large-gap: 1.25rem; // 20px
$header-height: 4rem;
$large-screen: 1024px;
$medium-screen: 768px;
$small-screen: 600px;
$xsmall-screen: 400px;

// Font Size
$small-font: 0.875rem; // 14px
$normal-font: 1rem; // 16px
$large-font: 1.125rem; // 18px
$xlarge-font: 1.5rem; // 24px
$xxlarge-font: 2.25rem; // 36px

// Other general settings
$wrapper-width: 1280px;
$transition-time: 0.4s;
$border-radius: 8px;
$box-shadow: 0 2px 4px rgba($black, 0.2);

// z-index layering
$z-index-values: (
  "navigation": 1,
);
